export default {
  "servus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servus"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
  "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilder"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angeforderte Seite konnte nicht gefunden werden!"])},
    "back-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])}
  },
  "units": {
    "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
    "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])}
  },
  "validation": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld wird benötigt"])},
    "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss akzeptiert werden"])},
    "password-confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Passwörter stimmen nicht überein"])},
    "invalid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail Adresse"])},
    "password-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 8 Zeichen"])},
    "password-upper-and-lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Gross- und ein Kleinbuchstabe"])},
    "invalid-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl oder Spezialzeichen enthalten"])},
    "code-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteht aus 5 Zeichen"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss eine Zahl sein"])},
    "duration-ban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf maximal 5000 Tage betragen"])}
  },
  "api-errors": {
    "INVALID_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiger Code"])},
    "NO_PERMISSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Berechtigung"])}
  },
  "dialog": {
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])}
  },
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])}
}