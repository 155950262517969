<template>
  <v-app id="v-app">
    <v-app-bar
        color="dark-background"
    >
      <v-spacer/>
      <h1 @click="checkForCat">{{ $t('servus') }}!</h1>
      <v-spacer/>
      <v-app-bar-nav-icon aria-label="Menu" variant="text" @click.stop="drawer = !drawer" v-if="isLoggedIn"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        temporary
        location="right"
        v-if="isLoggedIn"
    >
      <v-list density="compact" nav aria-label="page">
<!--        <v-list-item v-if="isLoggedIn" to="/account" role="option" aria-label="account" prepend-icon="mdi-account" :title="$t('account.title')" value="account"></v-list-item>-->
<!--        <v-list-item to="/" role="option" aria-label="home" prepend-icon="mdi-home-city" :title="$t('home.title')" value="home"></v-list-item>-->
<!--        <v-list-item to="/join" role="option" aria-label="join" prepend-icon="mdi-controller" :title="$t('join.button')" value="join"></v-list-item>-->
<!--        <v-list-item to="/rules" role="option" aria-label="rules" prepend-icon="mdi-book-open-page-variant-outline" :title="$t('rules.title')" value="rules"></v-list-item>-->
<!--        <v-list-item href="https://shop.statemc.de" role="option" aria-label="shop" prepend-icon="mdi-cart-outline" :title="$t('shop.title')" value="shop"></v-list-item>-->
<!--        <v-list-item to="/stats" role="option" aria-label="stats" prepend-icon="mdi-podium" :title="$t('stats.title')" value="stats"></v-list-item>-->
<!--        <v-list-item to="/downloads" role="option" aria-label="downloads" prepend-icon="mdi-download" :title="$t('downloads.title')" value="downloads"></v-list-item>-->
<!--        <v-list-item to="/wiki" role="option" aria-label="wiki" prepend-icon="mdi-wikipedia" :title="$t('wiki.title')" value="wiki"></v-list-item>-->
<!--        <v-list-item to="/changelog" role="option" aria-label="changelog" prepend-icon="mdi-note-text-outline" :title="$t('changelog.title')" value="changelog"></v-list-item>-->
      </v-list>
    </v-navigation-drawer>
    <v-card elevation="0" >
      <v-layout>
        <v-main style="margin-top: 64px">
          <router-view></router-view>
        </v-main>
      </v-layout>
    </v-card>
    <edit-dialog v-model="checkForCatDialog" title="Du möchtest mehr sehen?" :cancel-button="false" :save-button="false">
      <v-text-field
          v-model="cFCInput"
          label="Passwort"
          type="password"
          autocomplete="off"
          @keyup.enter="checkForCatPassword"
      ></v-text-field>
    </edit-dialog>
  </v-app>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref, watch } from 'vue';
import i18n from '@/i18n';
import EditDialog from '@/components/form/EditDialog.vue';

const store = useStore();

const locale = computed(() => store.getters.locale);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const drawer = ref(false);

const setLang = (locale) => {
  document.documentElement.setAttribute('lang', locale);
};

watch(locale, (newVal) => {
  setLang(newVal);
});

onMounted(() => {
  if (locale.value) {
    i18n.global.locale = locale.value;
  } else {
    setLang(i18n.global.locale);
  }
});

const checkForCatDialog = ref({
  display: false,
  error: null,
});
const cFCInput = ref('');

const checkForCat = () => {
  checkForCatDialog.value.display = true;
  checkForCatDialog.value.error = null;
};

const checkForCatPassword = () => {
  if (cFCInput.value === process.env.VUE_APP_CAT_PASSWORD) {
    checkForCatDialog.value.display = false;
    store.commit('login');
  } else {
    checkForCatDialog.value.error = 'Falsches Passwort!';
  }
};

</script>

<style>
h1, h2, h3, h4, h5 {
  color: rgb(var(--v-theme-primary)) !important;
}

.color-primary {
  color: rgb(var(--v-theme-primary)) !important;
}

.color-secondary {
  color: rgb(var(--v-theme-secondary)) !important;
}

.sm-view {
  max-width: 800px !important;
  margin: auto;
}

.m-view {
  max-width: 1200px !important;
  margin: auto;
}

.v-dialog .v-overlay__content {
  margin: 0 !important;
}

.lg-padding {
  padding-right: 15px;
  padding-left: 15px;
}

.subtitle {
  opacity: 1;
  -webkit-line-clamp: unset !important;
}

@media screen and (max-width: 800px) {
  .lg-padding {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>

<style scoped>
.brand {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
.clickable {
  cursor: pointer;
}
</style>
