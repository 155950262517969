import i18n from '@/i18n';

const state = {
  tempPath: undefined,
  error: undefined,
  errorShowing: false,
  locale: undefined,
};

const getters = {
  tempPath: ({ tempPath }) => tempPath,
  errorShowing: ({ errorShowing }) => errorShowing,
  error: ({ error }) => error,
  locale: ({ locale }) => locale,
};

const actions = {

};

const mutations = {
  setTempPath (state, tempPath) {
    state.tempPath = tempPath;
  },
  setErrorShowing (state, errorShowing) {
    state.errorShowing = errorShowing;
  },
  displayError (state, { title = 'error', text }) {
    state.error = {
      title,
      text,
    };
    state.errorShowing = true;
  },
  setLocale (state, locale) {
    state.locale = locale;
    i18n.global.locale = locale;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
