import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import common from '@/store/common';
import auth from '@/store/auth';

export default new Vuex.Store({
  modules: {
    common,
    auth,
  },
  plugins: [createPersistedState({
    reducer: (state) => ({
      auth: {
        passwordMatch: state.auth.passwordMatch,
        lastMatch: state.auth.lastMatch,
      },
      common: {
        locale: state.common.locale,
      },
    }),
  })],
  strict: true,
});
