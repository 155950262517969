import router from '@/router';

const state = {
  passwordMatch: false,
  lastMatch: undefined,
};

const getters = {
  isLoggedIn: ({ passwordMatch }) => passwordMatch,
  isExpired: ({ lastMatch }) => {
    if (lastMatch) {
      const now = new Date().getTime() / 1000;
      return now - lastMatch > 60 * 60;
    }
    return false;
  },
};

const actions = {

};

const mutations = {
  login (state) {
    state.passwordMatch = true;
    state.lastMatch = new Date().getTime() / 1000;
  },
  logout (state, reload = true) {
    if (reload) {
      router.go();
    }
    state.passwordMatch = false;
    state.lastMatch = undefined;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
