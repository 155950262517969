<template>
  <v-dialog
      v-model="dialog"
      class="dialog"
  >
    <v-card>
      <v-alert type="error" v-if="modelValue.error" class="mr-3 ml-3">{{ $t(`api-errors.${modelValue.error}`) }}</v-alert>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-form
          v-model="form"
          @submit.prevent="save"
      >
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn v-if="cancelButton" color="secondary" variant="elevated" @click="dialog = false">{{ saveButton ? $t('dialog.abort') : $t('dialog.close') }}</v-btn>
          <v-btn v-if="saveButton" color="primary" variant="elevated" type="submit" :loading="modelValue.loading">{{ $t('dialog.save') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, ref } from 'vue';

const emit = defineEmits(['update:modelValue', 'save']);

const props = defineProps({
  title: String,
  modelValue: Object,
  cancelButton: {
    type: Boolean,
    required: false,
    default: true,
  },
  saveButton: {
    type: Boolean,
    required: false,
    default: true,
  },
});
const dialog = computed({
  get: () => props.modelValue.display ?? false,
  set: (val) => {
    emit('update:modelValue', {
      ...props.modelValue,
      error: null,
      display: val,
    });
  },
});

const form = ref(false);

const save = () => {
  if (!form.value) return;
  emit('save');
};
</script>

<style scoped>
.dialog {
  max-width: 500px;
}
</style>
