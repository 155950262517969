import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

const Home = () => import('@/views/Home');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn) {
    if (!store.getters.isExpired) {
      store.commit('logout', false);
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.commit('setTempPath', to.path);
      next({ name: 'Home' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresNotLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
