import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'default',
    themes: {
      default: {
        dark: true,
        colors: {
          primary: '#228B22',
          'dark-background': '#232323',
          surface: '#2e2e30',
          'on-primary': '#ffffff',
          secondary: '#909090',
          accent: '#ffc107',
          error: '#f44336',
          warning: '#e7802d',
          info: '#03a9f4',
          success: '#4caf50',
        },
      },
    },
  },
});

export default vuetify;
